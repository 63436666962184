#evt_card {
  /* width: 250px; */
  width: 22.5em;
  height: 18em;
  margin: 30px 0px 0px 0px;
  border-radius: 20px;
  overflow-y: hidden;
  position: relative;
}
.lower_part_card {
  padding: 5px 10px 15px 10px;
  background-color: #3c3c5e;
  width: 300px;
  margin-bottom: 30px;
}
.Title_comp {
  color: white;
  font-size: 25px;
  text-align: center;
  margin: auto;
  width: fit-content;
  cursor: pointer;
}
.title_mobile {
  color: white;
  font-size: 25px;
}
#background_img {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  position: absolute;
  -webkit-transition: opacity 0.5s;
}
#background_img2 {
  width: 250px;
  height: 150px;
  -webkit-transition: opacity 0.5s;
}
#card_details {
  opacity: 0;
  border-radius: 20px;
  background-color: rgba(128, 128, 128, 0.226);
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.5s;
  margin-left: 0px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

#evt_card:hover #background_img {
  animation: fade1 0.5s ease-out;
  opacity: 0.3;
}
#evt_card:hover #card_details {
  opacity: 1;
  background-color: rgba(128, 128, 128, 0.226);
  animation: glow1 0.5s ease-out;
  background-size: cover;
  font-display: block;
}
#evt_card:hover {
  cursor: pointer;
}

#margins {
  color: white;
  display: block;
  opacity: 1;
  text-align: justify;
  overflow-y: hidden;
}

#content_head {
  padding-top: 1%;
  font-size: 50px;
  font-weight: bolder;
  text-align: center;
}

#prize {
  font-size: 1.5rem;
  margin-top: 5px;
  padding-left: 3%;
}
.sndbtn {
  margin-top: 15px;
}
#firstbtn {
  margin-top: 15px;
  background: rgb(196, 185, 226, 0.4);
  border: 0;
  border-radius: 0;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 1000;
  padding: 5px;
  outline: none;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  width: fit-content;
}
#firstbtn:hover {
  background-color: white;
  color: black;
}
@keyframes fade1 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
#prize_h6 {
  color: #ffffff;
}
@keyframes glow1 {
  0% {
    opacity: 0;
    background-color: rgba(128, 128, 128, 0.226);
  }
  100% {
    background-color: rgba(128, 128, 128, 0.226);
    opacity: 1;
  }
}
.card_mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .des {
    height: 150px;
    overflow-y: hidden;
  }
  #card_content {
    max-height: 30rem;
    overflow-y: hidden;
    margin: 0 1rem 1rem auto;
    width: 69%;
  }
  .Title_comp {
    display: none;
  }
  #card_details {
    border-radius: 0px;
  }
  .card_desk {
    display: none;
  }
  .card_mobile {
    display: block;
  }
  #background_img {
    border-radius: 0px;
  }
  #firstbtn {
    margin-left: 0px;
    font-weight: 600;
    background-color: #ffffff;
    color: black;
  }
  .lower_part_card {
    width: 100%;
    margin: 0;
  }
  #background_img2{
    width: 100%;
    height: 40vw;
  }
}
