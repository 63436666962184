.Footer {
    width: 100vw;
    /* min-height: 80vh; */
    height: 90vh;
    position: relative;
    bottom: 0;
    padding: 2rem 3rem;
    padding-bottom: 3rem;
    --col-primary: #4b27a9;
    box-sizing: border-box;
    font-family: "NeueKabel", sans-serif;
    color: var(--col-primary);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #010436;
    font-size: 95%;
}

.footerColumns a {
    text-decoration: none;
    color: white;
    height: fit-content;
    flex: 1;
    text-align: center;
}

.footerColumns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: .5rem 2rem;
    padding: 0.8rem;
}

.footerLogo {
    display: flex;
    flex-direction: column;
    font-size: .9rem;
    font-weight: lighter;
    color: white;
    justify-content: center;
    text-align: justify;
}

.footerLogo a {
    text-decoration: none;
    height: fit-content;
    /* flex: 1; */
    color: white;
    text-align: center;
}

.connectlinks a {
    text-decoration: none;
    height: fit-content;
    flex: 1;
    color: white;
    text-align: center;
}


/* .Footer a:hover {
} */

.footerColumns li:not(:first-child):hover {
    /* opacity: 0.8; */
    text-shadow: 0 0 0.5vh lavenderblush;
}

.contactUs:hover {
    text-shadow: 0 0 0.5vh lavenderblush;
}

.Homepagelinks,
.Otherlinks {
    /* border-top: 2px solid var(--col-primary); */
    display: flex;
    /* justify-content: space-between; */
    font-weight: 500;
    flex: 1;
}

.Homepagelinks>*:last-child {
    /* .Otherlinks > *:last-child { */
    margin-left: auto;
}

.thirddiv {
    /* border-top: 2px solid var(--col-primary); */
    /* font-weight: 600; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Homepagelinks,
.thirddiv {
    padding: 0.4rem 0;
}

.Otherlinks {
    padding: 1.7rem 0;
    flex: 1;
}

.title {
    font-size: 2.2rem;
}

.title img {
    width: 100%;
    height: auto;
}

.Activitieslinks li:first-child {
    font-size: 2rem;
    font-weight: bold;
    /* flex: 2; */
}

.box {
    /* height: 1.4rem; */
    font-size: 1.2rem;
    line-height: 1.3rem;
    font-weight: lighter;
}

.emptyBox {
    visibility: hidden;
}

.noBox {
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.emptyBox,
.noBox,
.box,
.headingBox,
.specialBox {
    color: white;
}

.box:hover,
.contactUs:hover,
.specialBox span:hover {
    text-shadow: 0 0 0.5vh lavenderblush;
}

@media only screen and (min-width: 791px) {
    .footerColumns {
        margin-left: 2rem;
        flex: 4;
    }
    .footerLogo {
        width: 23%;
        /* display: flex; */
        flex: 3;
        align-items: center;
    }
    .sponsBox {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 6;
        grid-row-end: 7;
        font-size: 1.8rem;
        line-height: 1;
        font-weight: 500;
    }
    .specialBox {
        /* height: 2.1rem; */
        font-size: 1.8rem;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.7rem;
        vertical-align: bottom;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 4;
        grid-row-end: 6;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .headingBox {
        position: relative;
        bottom: 0.5rem;
        font-size: 1.8rem;
        height: 2.1rem;
        line-height: 1;
    }
}

@media only screen and (max-height: 700px) {
    .sponsBox {
        bottom: 1rem;
    }
}

.letsconnect {
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    flex: 2;
    font-size: 19px;
}

.letsconnect p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.connectlinks {
    width: 60%;
    display: flex;
    justify-content: space-around;
    font-size: 1.8rem;
}

.register {
    width: 50%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    flex: 3;
}

.register input {
    background-color: transparent;
    outline: none;
    border: 0px solid white;
    color: rgb(136, 195, 247);
    flex: 1;
    padding: 0.2rem 1rem;
}

.register label {
    width: auto;
}

.register input[type="submit"] {
    border: 1px solid white;
    border-radius: 50px;
    background-color: white;
}

.footerEmail {
    border: 1px solid white;
    border-radius: 50px;
}

.register label {
    margin: 0 0rem;
}

.onlyMobile,
.mobile {
    display: none;
}

@media only screen and (max-width: 790px) {
    .onlyDesktop {
        display: none;
    }
    .footerColumns {
        display: none;
    }
    .box {
        font-size: 1.1rem;
        line-height: 1.1rem;
    }
    /* .box,
    .headingBox {
        height: 1.4rem;
    } */
    .footerImgDivs {
        align-items: flex-start;
        height: 100%;
    }
    .mobileFooter {
        display: flex;
        flex-direction: column;
        /* flex: 3; */
    }
    .mobileFooterG1 a,
    .mobileFooterG2 a,
    .mobileFooterG3 a,
    .mobileFooterG4 a {
        text-decoration: none;
        color: white;
        height: fit-content;
        flex: 1;
        text-align: center;
    }
    .mobileFooterG1,
    .mobileFooterG2,
    .mobileFooterG3,
    .mobileFooterG4 {
        display: grid;
        grid-gap: .3rem 2rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: 0.3rem 0rem;
    }
    .mobileFooterG1 {
        grid-template-rows: repeat(3, 1fr);
        padding-top: 0;
        ;
    }
    .mobileFooterG2 {
        grid-template-rows: repeat(1, 1fr);
    }
    .mobileFooterG3 {
        grid-template-rows: repeat(4, 1fr);
        padding-top: 0;
    }
    .mobileFooterG4 {
        grid-template-rows: repeat(1, 1fr);
        padding-bottom: 0;
    }
    .headingBox {
        position: relative;
        font-size: 1.1rem;
        font-weight: bold;
        height: 2.1rem;
        line-height: 1.1rem;
    }
    .mobileContactUs {
        grid-column-start: 2;
        grid-column-end: 4;
    }
    .sitemapHeading {
        grid-row-start: 1;
        grid-row-end: 4;
    }
    .activitiesHeading {
        grid-row-start: 1;
        grid-row-end: 5;
    }
    .doubleLines {
        grid-row-start: 2;
        grid-row-end: 4;
    }
    .onlyMobile {
        display: unset;
    }
    .emptyBox,
    .noBox,
    .box,
    .headingBox,
    .specialBox {
        display: none
    }
    .mobile {
        display: flex;
    }
    .doubleLines {
        align-items: center
    }
    .mobile a {
        text-align: left;
    }
    .footerLogo a {
        height: 100%;
    }
    .footerLogo p {
        font-size: 0.6rem;
        padding-left: 0.5rem;
    }
    .title {
        font-size: inherit;
    }
    .thirddiv {
        flex-direction: column-reverse;
        row-gap: 1.5em;
    }
    .Otherlinks>*:not(:last-child) {
        width: 100%;
    }
    .register {
        width: 100%;
    }
    .register label {
        margin-bottom: 0.5em;
    }
    .footerEmail {
        display: flex;
    }
    .connectlinks {
        width: 90%;
    }
    .Otherlinks {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding-bottom: 0.1em;
        padding: 0
    }
    .Otherlinks>*:not(:last-child) {
        justify-content: center;
        flex-direction: row;
    }
    .Otherlinks img {
        width: 7em !important;
    }
    .footerColumns {
        /* flex-wrap: wrap; */
        /* margin-top: -2em; */
    }
    .letsconnect p {
        margin-bottom: 0rem;
        font-size: 1.3rem;
    }
    .Footer {
        padding: 2em 1em;
        margin-right: 2em;
    }
    .Activitieslinks {
        margin: 1em 2em;
    }
    .Footer {
        /* background-image: linear-gradient(#3c3c5e, #0f1240, #0f1240); */
        background-color: #010436;
    }
}