.bgvideo {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-position: center;
  opacity: 1;
}
.bgvideo_content {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}
