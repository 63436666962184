@media (min-width: 997px) {
  .signOuter {
    position: absolute;
    width: 30%;
    height: 25%;
    top: 45%;
    left: 60%;
    z-index: 10;
    font-family: 'NeueKabel';
    background-image: linear-gradient(
      145deg,
      rgb(255 255 255 / 35%),
      rgb(255 255 255 / 10%)
    );
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .formOuter {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    font-family: 'NeueKabel';
  }

  .formSubmit {
    border-radius: 6px;
    width: fit-content;
    font-size: 1rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 10px;
    font-family: 'NeueKabel';
  }
  .red-text-field {
    border-bottom: 3px solid red;
  }
  .Email {
    font-size: 1.1rem;
    color: white;
    padding: 5px;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 10px;
    border-bottom: 5px solid rgb(196, 134, 238);
    outline: none;
    font-family: 'NeueKabel';
  }
  .Email::placeholder {
    color: white;
    outline: none;
    margin-top: 2%;
    margin-left: 2%;
    font-family: 'NeueKabel';
  }
}

@media (max-width: 996px) {
  .signOuter {
    position: absolute;
    top: 30%;
    left: 6%;
    width: 90%;
    height: 25%;
    z-index: 10;
    background-image: linear-gradient(
      145deg,
      rgb(255 255 255 / 35%),
      rgb(255 255 255 / 10%)
    );
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .formOuter {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  .formSubmit {
    border-radius: 6px;
    width: fit-content;
    font-size: 1rem;
    background-color: transparent;
    color: rgb(214, 185, 226);
    border: 3px solid rgb(220, 195, 236);
    padding: 10px;
  }
  .red-text-field {
    border-bottom: 3px solid red;
  }
  .Email {
    font-size: 1.1rem;
    color: white;
    padding: 5px;
    margin-top: 5%;
    width: 90vw;
    background-color: transparent;
    margin-bottom: 10px;
    border-bottom: 5px solid rgb(196, 134, 238);
    outline: none;
  }
  .Email::placeholder {
    color: white;
    outline: none;
    margin-top: 2%;
    margin-left: 2%;
    font-family: 'NeueKabel';
  }
}
