.row {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.centerimg {
  height: 130px;
}
.other2 {
  height: 100px;
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin: 0 5px;
}

.bImg {
  font-size: 2rem;
  height: 10rem;
  width: 10rem;
  border-radius: 2rem;
  object-fit: scale-down;
  /* object-fit:cover; */
  /* object-position: top;  */
}

.caro {
  width: 90vw;
  height: 50vh;
}

.frames {
  /* width: 5rem; */
  height: 100%;
  width: 100%;
  /* text-overflow: ellipsis; */
  align-items: center;
  white-space: pre-line;
  /* margin: auto; */
  /* display: flex; */
  flex-flow: row nowrap;
  /* overflow-x: scroll; */
  padding: 0.4rem;
  justify-content: center;
}

.slide {
  margin: auto;
  /* height: 50vh; */
  width: 100vw;
  background-color: rgba(0, 0, 0, 0);
  white-space: nowrap;
  /* display: inline-block; */
  color: white;
  text-align: center;
  text-decoration: none;
  margin-bottom: 2.4rem;
}
@media only screen and (min-width: 800px) {
  .slide {
    width: 70vw;
  }
}
@media only screen and (min-width: 1000px) {
  .slide {
    width: 50vw;
  }
}
