@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@font-face {
    font-family: "AvenirLTStd-Black";
    src: url("./fonts/AvenirLTStd-Black.otf");
}

@font-face {
    font-family: "AvenirLTStd-Roman";
    src: url("./fonts/AvenirLTStd-Roman.otf");
}

@font-face {
    font-family: "Rheiborn_Sans_Clean";
    src: url("./fonts/Rheiborn_Sans_Clean.otf");
}

@font-face {
    font-family: "NeueKabel";
    src: url("./fonts/NeueKabel.otf");
}

@font-face {
    font-family: "NeueKabel";
    src: url("./fonts/NeueKabel-Bold.otf");
    font-weight: 700;
}

@font-face {
    font-family: "NeueKabel";
    src: url("./fonts/NeueKabel-Black.otf");
    font-weight: 900;
}

@font-face {
    font-family: "NeueKabel";
    src: url("./fonts/NeueKabel-ExtraLight.otf");
    font-weight: 300;
}

@font-face {
    font-family: "Azonix";
    src: url("./fonts/Azonix-1VB0.otf");
}

html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

html body {
    font-family: "NeueKabel", "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (max-width: 1024px) {
    html {
        font-size: 80%;
    }
}

@media (min-width: 1440px) {
    html {
        font-size: 110%;
    }
}

@media (max-width: 876px) {
    html {
        font-size: 100%;
    }
}

.App {
    position: relative;
    width: 100vw;
    /* overflow: hidden; */
    z-index: 0;
    background-color: #010436;
}

#App {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    margin: 0;
    height: calc(100% + 1px);
}

#App {
    height: 100%;
    /* overflow: hidden; */
}

@media only screen and (max-width:998px) {
    #App {
        height: 92%;
    }
}

#App::-webkit-scrollbar {
    display: none;
}

#App {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.normal {
    transform: scale(0.1);
    --left-adjust: 50.3938%;
    --top-adjust: 27.7248%;
}

.zoomin {
    transform: scale(5.95);
    transition: transform 1.5s cubic-bezier(0.7, 0, 0.84, 0);
}

.zoomout {
    transform: scale(0.1);
    transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
}

.changeframe {
    transition: transform-origin 1.3s ease-in-out, top 1.3s ease-in-out, left 1.3s ease-in-out;
}

* {
    font-size: inherit;
}

.frame__content-enter {
    transform: translateX(150%);
}

.frame__content-enter-active {
    transform: translateX(0);
    transition: all 1s;
}

.frame__content-exit {
    opacity: 1;
}

.frame__content-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.5s;
}

.frame__content-exit-done {
    opacity: 0;
}

#home~#footer {
    display: none;
}