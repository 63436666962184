.NBar {
  z-index: 9999;
  position: fixed;
  height: 10vh;
  width: 100vw;
  top: 0;
  left: 0;
  /* backdrop-filter:blur(4px); */
  /* background-image: linear-gradient(to bottom, rgb(255, 255, 255, 0.3), rgb(255, 255, 255, 0.1)); */
  /* background-image: url("../../images/navbar/tabs-gradient.png"); */
  background-color: rgba(1, 4, 54, 0.7);
  font-family: "Azonix";
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  align-items: center;
  border-right: 5px solid rgb(1, 4, 54);
  box-sizing: border-box;
}

/* ----------------------------------------------- */

.NavLogo {
  height: 100%;
}

/* navbar simple links */
.uldesp {
  background-color: #04074a !important;
}
.NBar ul {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  margin: 0;
  height: 100%;
}
.notify_box {
  border: 2px solid rgba(0, 0, 0, 0);
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  font-family: NeueKabel, Ouicksand;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.notify_box:hover {
  /* transition: all 1s ease; */
  /* padding: 0.2rem; */
  border: 2px solid rgba(255, 255, 255, 0.521);
  border-image: conic-gradient(
      rgba(255, 255, 255, 0.616),
      rgba(255, 255, 255, 0.616),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.678),
      rgba(255, 255, 255, 0.678),
      rgba(255, 255, 255, 0.671),
      rgba(255, 255, 255, 0.651),
      rgba(255, 255, 255, 0.623),
      rgba(255, 255, 255, 0.63),
      rgba(255, 255, 255, 0.596),
      rgba(255, 255, 255, 0.616),
      rgba(255, 255, 255, 0.616)
    )
    1;
  background-image: linear-gradient(
    to right,
    rgba(113, 117, 134, 0.568),
    rgba(58, 57, 57, 0.644)
  );
}

.notify_subheader {
  display: flex;
  flex-direction: column;
}

.notify_imagebox {
  width: 30%;
  margin-left: 1rem;
}

.notify_image {
  width: 100%;
}

.notify_imagebox .notify_image {
  margin: 0;
  height: auto;
}

.notify_header {
  font-weight: 600;
  font-size: 130%;
}

.notify_time {
  font-weight: 100;
  font-size: 110%;
}

.NBar ul li {
  height: 100%;
}

.NBar ul li a {
  text-decoration: none;
  color: white;
}

.NBar ul li div .stext a {
  font-size: 0.8rem;
}

.NBar ul li a:hover {
  text-shadow: 0 0 0.5vh lavenderblush;
}

.sbox_outer {
  position: relative;
  top: 0vh;
}

.sbox_outer:first-child {
  z-index: 4;
  order: 3;
  height: 100%;
}

.sbox {
  order: 2;
  transition: max-height 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  max-height: 0px;
  overflow: hidden;
  z-index: -3;
  white-space: nowrap;
  width: max-content;
  color: white;
  margin-top: 0;
  position: absolute;
  border-radius: 0.1rem;
  background-image: linear-gradient(
    to right,
    rgba(112, 116, 134, 0.45),
    rgba(51, 49, 74, 0.45)
  );
  font-weight: 400;
}

.stext {
  padding: 0.6rem 1.5rem 0.6rem 1rem;
  z-index: 3;
  cursor: pointer;
  text-align: start;
  border: 2px solid transparent;
  font-weight: 400 !important;
}

.stext a {
  font-weight: 400;
  position: relative;
  text-shadow: none;
  margin: 0 !important;
}
.sbox:hover a,
.sbox_outer:hover > .sbox a {
  font-weight: 400;
}

.stext:hover a {
  font-weight: 400;
  text-align: start;
  text-shadow: none;
}

.stext:hover a:hover {
  font-weight: 400;
  text-align: start;
  text-shadow: none;
}

.NBar ul li a:hover + .sbox {
  display: block;
  opacity: 1;
  max-height: 90vh;
}

.sbox:hover {
  display: block;
  z-index: 3;
  max-height: 90vh;
  opacity: 1;
  font-weight: 400;
}

.stext:hover {
  border-radius: 0.3rem;
  border: 2px solid rgba(255, 255, 255, 0.521);
  border-image: conic-gradient(
      rgba(255, 255, 255, 0.616),
      rgba(255, 255, 255, 0.616),
      rgba(255, 255, 255, 0.623),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.678),
      rgba(255, 255, 255, 0.671),
      rgba(255, 255, 255, 0.651),
      rgba(255, 255, 255, 0.623),
      rgba(255, 255, 255, 0.63),
      rgba(255, 255, 255, 0.596),
      rgba(255, 255, 255, 0.616)
    )
    1;
  background-image: linear-gradient(
    to right,
    rgba(113, 117, 134, 0.548),
    rgba(255, 255, 255, 0.034)
  );
}

.bellicon {
  background-color: red;
  /* flex: 2; */
}

.hamburger {
  display: none;
  margin-right: 20px;
}
.hamburger1 {
  margin-top: 20px;
  margin-right: 10px;
}
.hamburger1.active .bar:nth-child(2) {
  opacity: 0;
}
.hamburger1.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}
.hamburger1.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
/* ----------------------------------------------- */

/* sign in, sign up buttons  */

.signInUp {
  z-index: 7;
  /* padding: 0.5rem; */
  margin: 0.5rem;
  height: fit-content !important;
  white-space: nowrap;
}

.Sinup {
  margin-left: 0.3rem;
}

.Sinin,
.Sinup {
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border: 2px solid white;
  border-radius: 0.3rem;
}

.Sinin,
.Sinup {
  transition: all 0.5s ease 0s;
}

.Sinin a,
.Sinup a {
  transition: all 0.5s ease 0s;
  margin: 0 !important;
  padding: 0.3rem 0.7rem;
}

.Sinin:hover a {
  color: rgb(0, 17, 95);
  text-shadow: none;
}

.Sinin:hover {
  background-color: rgb(176, 181, 205);
  border: 2px solid rgb(176, 181, 205);
}

.Sinup:hover a {
  color: rgb(0, 17, 95);
  text-shadow: none;
}

.Sinup:hover {
  background-color: rgb(176, 181, 205);
  border: 2px solid rgb(176, 181, 205);
}

.design {
  border: 3px solid rgb(0, 0, 0);
  border-radius: 10px;
  padding: 12px 50px 12px 50px;
}

.design:hover {
  background-color: aliceblue;
  transition: 0.5s ease 0s;
}

/* ----------------------------------------------- */

/* mobile nav bar  */

.mobileIcon {
  display: none;
}
.navback {
  width: 100%;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  background-color: rgba(1, 4, 54, 0.7);
  justify-content: flex-end;
  font-size: 50px;
  color: white;
  text-align: right !important;
}
.navback1 {
  justify-content: flex-end;
  font-size: 50px;
  color: white;
  text-align: right !important;
}
.navback:hover {
  cursor: pointer;
}
.spacer {
  flex-grow: 1;
}
.siginsignup {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}
.whitebdr {
  padding-left: 10px;
  border-right: 5px solid white;
  display: inline-block;
}

@keyframes slide {
  0% {
    margin-right: -250px;
  }
  100% {
    margin-right: 0px;
  }
}
.animate {
  animation-name: slide1;
  animation-duration: 3s;
}

.backdrop {
  color: #ffffff;
  z-index: 1000 !important;
}
.gradientdiv {
  flex-direction: column;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  height: 100%;
  animation-name: slide;
  animation-duration: 0.5s;
  width: 100%;
}

.gradientdiv2 {
  flex-direction: column;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  height: 100%;
  transition: width 0.3s;
  z-index: 999999;
}

.gradientdiv1 {
  flex-direction: column;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  background-color: #04074a;
  height: 100%;
}
.gd2 {
  width: 100%;
  height: 100%;
}

/* hambuger menu  */
@media only screen and (max-width: 1300px) {
  .NBar {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 1100px) {
  .NBar {
    font-size: 0.65rem;
  }
}
@media only screen and (max-width: 998px) {
  .NBar {
    display: none;
  }
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");
  .header {
    zoom: 62.5%;
    background-color: rgba(0, 0, 255, 0);
    background: transparent;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  .header {
    border-bottom: 1px solid #e2e8f0;
    z-index: 100000;
    height: 8%;
  }
  .tmp {
    display: none;
  }
  .act {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    top: 0;
    left: 0;
  }
  .navbar {
    height: 100%;
    display: flex;
    justify-content: space-between;

    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 2;
    background-color: #22266c;
  }
  .hamburger {
    display: none;
  }
  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: rgb(255, 255, 255);
  }
  .NavMenu {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    z-index: 1000;
  }
  .NavMenu1 {
    opacity: 0;
    position: fixed;
    right: -100%;
    top: 0;
    flex-direction: column;
    background: #010436;
    /* padding: 20px; */
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    height: 100%;
    background-color: #04074a;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    z-index: 1000;
    width: 75%;
    min-width: 445px;
  }
  .NavItem {
    margin-left: 5rem;
  }
  .NavLink {
    font-size: 1.6rem;
    font-weight: 400;
    color: black;
  }
  .NavLink:hover {
    color: #482ff7;
  }
  .NavLogo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #000000;
  }
  .NavMenu {
    opacity: 0;
    position: fixed;
    right: -100%;
    top: 0;
    flex-direction: column;
    background: #010436;
    /* padding: 20px; */
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    height: 100%;
    background-color: #04074a;
  }
  .NavMenu.active {
    opacity: 1;
    right: 0;
  }
  .NavMenu1 {
    opacity: 0;
    position: fixed;
    right: -100%;
    top: 0;
    flex-direction: column;
    background: #010436;
    /* padding: 20px; */
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    height: 100%;
    background-color: #04074a;
  }
  .NavMenu1.active {
    opacity: 1;
    right: 0;
  }
  .NavItem {
    margin: 2.2rem 0 2.6rem 0;
  }
  .NavItem_com {
    margin: 2.2rem 0 0rem 0;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    transform: scale(1.6);
  }
  .mobileIcon {
    display: inline;
    height: 80px;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  .navbar ul li a {
    font-weight: 600;
    text-decoration: none;
    color: white;
    margin: 1vw;
    padding-left: 0.7vw;
    padding-right: 0.7vw;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    font-size: 2em;
  }
  .notify_box {
    font-size: 1.4rem;
  }
  .notify_header {
    font-size: 1.6rem;
  }
}

.Nicon {
  height: 1.5rem;
}

@media only screen and (min-width: 998px) {
  .header {
    display: none;
  }
}

.HamOuter img {
  margin-left: 2.3rem;
  margin-top: -0.5rem;
  padding: 0.5rem;
  height: 3.2rem;
}
.HamOuter1 img {
  margin-left: 2.3rem;
  margin-top: -0.5rem;
  height: 3.2rem;
}

.HamOuterStyle img {
  margin-left: 2.3rem;
  margin-top: -0.5rem;
  padding: 0.5rem;
  height: 3.2rem;
}
.HamOuter1 {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  background-color: rgba(1, 4, 54, 0.7);
}
.HamOuterStyle1 {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  background-color: rgba(1, 4, 54, 0.7);
}
.HamOuterStyle1 img {
  margin-left: 2.3rem;
  margin-top: -0.5rem;
  height: 3.2rem;
}
.MobNavItem {
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  color: white;
  /* margin: 1vw; */
  /* padding-left: 0.7vw; */
  /* padding-right: 0.7vw; */
  /* padding-top: 1rem; */
  font-size: 2em;
  /* padding-bottom: 0.5rem; */
  margin-bottom: 0;
  /* margin-left: 2.5rem 0; */
  height: min-content;
  /* transition: max-height 1s cubic-bezier(0.785, 0.135, 0.15, 0.86); */
  z-index: 99999999;
}

.MobNavItem::-webkit-scrollbar {
  /* padding-top: 10vh; */
  width: 4px;
  background-color: rgba(240, 248, 255, 0.322);
  border-radius: 10px;
}

/* Track */
.MobNavItem::-webkit-scrollbar-track {
  height: 50px;
  /* padding-top: 10vh; */
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.MobNavItem::-webkit-scrollbar-thumb {
  /* padding-top: 10vh; */
  background: rgb(241, 236, 236);
  border-radius: 10px;
}

/* Handle on hover */
.MobNavItem::-webkit-scrollbar-thumb:hover {
  padding-top: 10vh;
  background: rgba(236, 233, 233, 0.603);
}

.HamMas {
  padding-left: 5.2rem;
  padding-top: 1.3rem;
}
.HamMas2 {
  padding-left: 5.2rem;
  padding-top: 1.6rem;
}
.HamMasGlow {
  padding-left: 5.2rem;
  padding-top: 1rem;
  text-shadow: 0 0 0.5vh lavenderblush;
}

.HamOuterStyle {
  transition: all 1s ease;
  padding: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.521);
  border-image: conic-gradient(
      rgba(255, 255, 255, 0.616),
      rgba(255, 255, 255, 0.616),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.678),
      rgba(255, 255, 255, 0.678),
      rgba(255, 255, 255, 0.671),
      rgba(255, 255, 255, 0.651),
      rgba(255, 255, 255, 0.623),
      rgba(255, 255, 255, 0.63),
      rgba(255, 255, 255, 0.596),
      rgba(255, 255, 255, 0.616),
      rgba(255, 255, 255, 0.616)
    )
    1;
  background-image: linear-gradient(
    to right,
    rgba(113, 117, 134, 0.568),
    rgba(58, 57, 57, 0.644)
  );
}
.HamInvisi {
  transition: max-height 0.5s ease;
  max-height: 0px;
  overflow: hidden;
}
.HamVisi {
  transition: max-height 0.5s ease;
  display: block;
  z-index: 3;
  max-height: 90vh;
}
.HamDrop {
  font-weight: 300 !important;
  font-size: 1.8rem !important;
}
.rot {
  transform: rotate(-180deg);
  transition: all 0.5s ease;
}
.ori {
  transition: all 0.5s ease;
}
.linkboxinner {
  height: 100%;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
}
.Navlinks {
  height: 100%;
}
