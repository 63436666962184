.image_competition {
  height: 100%;
  width: 100%;
}

.image_comp {
  height: 100%;
  position: absolute;
}

#all_cards {
  height: 100%;
  z-index: 2;
  margin: 0 auto;
  padding-left: 20vw;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
}

#all_cards_mobile {
  display: none;
}

.Heading_competition_mobile {
  font-size: 40px;
  color: white;
  text-align: center;
}

.image_mobile_comp {
  display: none;
}

.Heading_competition {
  /* font-size: 50px; */
  color: white;
  /* text-align: center; */
  margin-left: 18vw;
  margin-top: 2rem;
    font-size: 3.5rem;
    font-weight: 900;
  margin-bottom: 0 !important;
}

.section::-webkit-scrollbar {
  width: 10px;
}

.section::-webkit-scrollbar-track {
  background-color: rgba(167, 161, 161, 0.3);
}

.section::-webkit-scrollbar-thumb {
  border-left: 0;
  border-right: 0;
  background-color: white;
}

.section {
  flex: 1 2 300px;
  padding-left: 5em;
  padding-right: 5em;
  overflow-y: auto;
  direction: ltr;
  scrollbar-color: white;
  scrollbar-width: thin;
  justify-content: space-around !important;
  flex-flow: row wrap;
  height: 500px;
  column-gap: em !important;
}

@media screen and (max-width: 768px) {
  .image_comp {
    display: none;
  }
  .section {
    max-height: 80vh;
    padding: 0;
    align-self: flex-end;
  }
  .image_mobile_comp {
    display: block;
    position: absolute;
  }
  #all_cards {
    display: none;
  }
  #all_cards_mobile {
    display: flex;
    position: relative;
    top: 0%;
    right: 0px;
    z-index: 2;
    height: 100%;
    flex-direction: column;
  }
}

.comp_page {
  font-family: "NeueKabel";
  height: 100%;
  background-image: linear-gradient(154deg, #00002c, #091246, #13225c);
}

#competition-contents {
  width: 100vw;
  min-height: 100vh;
}
