.container {
  max-width: 400px;
  margin: 16px 10px 0px 10px;
  font-family: 'NeueKabel';
}

.signOuter {
  position: absolute;
  top: 30%;
  left: 90%;
  z-index: 10;
  font-family: 'NeueKabel';
}

.signinform {
  position: absolute;
  right: 15%;
  top: 40%;
  width: 430px;

  background-image: linear-gradient(
    145deg,
    rgb(255 255 255 / 35%),
    rgb(255 255 255 / 10%)
  );
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  font-family: 'NeueKabel';
}

.signinform1 {
  top: 25%;
  height: 280px;
}
.googleButtonPara {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5%;
  height: 15vh;
}
.alreadySignin {
  font-family: 'NeueKabel';
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-top: 3%;
}
input[type='text'],
input[type='password'] {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #42649e;
}

.input_field {
  width: 95%;
  padding: 10px;
  outline: none;
}

.input_field::placeholder {
  color: white;
  opacity: 1;
}

.button_group_signin {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

Button:focus {
  outline: none;
}

.aeeE {
  display: inline-block;
  color: white;
  font-family: 'NeueKabel';
  text-decoration: underline;
}

@media (max-width: 996px) {
  .container {
    margin: 10px 10px 0px 10px;
  }
  .signOuter {
    position: absolute;
    top: 8%;
    left: 90%;
    z-index: 10;
  }
  .signinform {
    width: 90vw;
    transform: translateX(6%);
    background-image: linear-gradient(
      145deg,
      rgb(255 255 255 / 35%),
      rgb(255 255 255 / 10%)
    );
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .button_group_signin {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .googleButtonPara {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5%;
    height: 10vh;
  }
}

@media (max-width: 415px) {
  .susn {
    margin-top: 13.4vh;
  }
}
.error_message {
  color: red;
}

.inputCol {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: white;
  line-height: 1;
}

.signinIcons {
  width: 5%;
  color: white;
}