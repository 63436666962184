* {
  margin: 0px;
  padding: 0px;
}

.msgWrapper {
  position: absolute;
  top: 20%;
  left: 60%;
  z-index: 10;
  font-family: 'NeueKabel', sans-serif;
  /* font-weight: 100; */
  /* background-color: #BDB3DC; */
  /* 
    Photoshop : 0deg => Left to Right 
    CSS : 0deg => Bottom to Top
    
    Background : rgba(255, 255, 255, 0.49) to rgba(255, 255, 255, 0.10) (-50deg) in PS
    Border : rgba(255, 255, 255, 0.55), rgba(19, 34, 91, 0.40) (-46deg) in PS
    
    
    Background : -140 deg in CSS
    Border : -136deg in CSS
    
    */
  background-image: linear-gradient(
    140deg,
    rgba(255, 255, 255, 0.49),
    rgba(255, 255, 255, 0.1)
  );
  border: 3px solid transparent;
  border-image: linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.55),
    rgba(19, 34, 91, 0.4)
  );
  width: 23rem;
  text-align: center;
  /* border-image-slice: 1; */
  /* border-radius: 1rem; */
}

.regLine {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 1rem; */
  color: white;
  font-weight: normal;
  font-size: 1.4rem;
}

.signUpEmail {
  font-family: 'NeueKabel', sans-serif;
  color: white;
  font-size: 1.5rem;
  font-weight: 400;
}

.ktjName {
  font-size: 2rem;
  color: #1f1863;
  padding-bottom: 1.5rem;
}

.year {
  font-size: 2rem;
  color: white;
}

.Idbox {
  /* background-color: white; */
  background-image: linear-gradient(
    140deg,
    rgba(255, 255, 255, 0.49),
    rgba(255, 255, 255, 0.1)
  );
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.55),
    rgba(19, 34, 91, 0.4)
  );
  width: 100%;
  margin: auto;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  font-weight: normal;
  font-size: 1.3rem;
  color: white;
  margin-bottom: 2.5rem;
}

.ClickHereLink {
  background-image: linear-gradient(
    140deg,
    rgba(255, 255, 255, 0.49),
    rgba(255, 255, 255, 0.1)
  );
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.55),
    rgba(19, 34, 91, 0.4)
  );
  color: white;
  font-weight: 300;
  padding: 0px 5px 0px 5px;
}

/* .ktjId{
  } */

.idIntial {
  color: white;
  font-weight: 300;
}

.note {
  width: 10rem;
  margin: auto;
  font-weight: 500;
  font-size: 1.2rem;
  color: white;
}

.explore {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 300;
  font-size: 1.2rem;
  color: #8cccf2;
}

@media (max-width: 996px) {
  .msgWrapper {
    position: absolute;
    top: 5.5%;
    z-index: 10;
    left: 8.5%;
    right: 8.5%;
    background-image: linear-gradient(
      140deg,
      rgba(255, 255, 255, 0.49),
      rgba(255, 255, 255, 0.1)
    );
    border: 3px solid transparent;
    border-image: linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.55),
      rgba(19, 34, 91, 0.4)
    );
    width: 23rem;
    text-align: center;
    width: 83vw;
    text-align: center;
  }
  .Idbox {
    background-image: linear-gradient(
      140deg,
      rgba(255, 255, 255, 0.49),
      rgba(255, 255, 255, 0.1)
    );
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-image: linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.55),
      rgba(19, 34, 91, 0.4)
    );
    font-size: 0.8rem;
    width: 15rem;
    margin: auto;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    font-weight: normal;
    margin-bottom: 0.5rem;
  }
  .regLine {
    font-size: 1.2rem;
  }
  .signUpEmail {
    font-size: 1.3rem;
  }
  .explore {
    /* padding-top: 0.5rem; */
    padding-bottom: 0.5rem;
    font-weight: 300;
    color: #8cccf2;
    font-size: 1rem;
  }
}
