.GamefestNewContainer {
  margin-top: 10vh;
  width: 100vw;
  background-color: rgba(1, 4, 54, 0.7);
  font-family: "NeueKabel";
}
.GamefestNewHeader {
  margin-top: 8vh;
  margin-bottom: 8vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.GamefestNewCard {
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin-left: 5vw;
  margin-bottom: 20px;
  min-height: 40vh;

  background-image: linear-gradient(
    145deg,
    rgb(255 255 255 / 35%),
    rgb(255 255 255 / 10%)
  );

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}
.buttons {
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.btngrp,.btngrp1 {
  background: rgb(196, 185, 226, 0.4);
  border: 0;
  border-radius: 0;
  color: #ffffff;
  border-image: conic-gradient( white, white, white, white, transparent, transparent, transparent, white, white, white, white, transparent, transparent, transparent, white ) 1;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 1000;
  
  padding: 0.9rem;
  outline: none;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  width: fit-content;
  margin-top: 20px;

}

.tab {
  font-family: "NeueKabel";
  font-size: 3rem;
  font-weight: bold;
  color: white;
  background-image: linear-gradient(
    145deg,
    rgb(255 255 255 / 35%),
    rgb(255 255 255 / 10%)
  );
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-top: 5%;
}
.description {
  font-family: "NeueKabel";
  font-size: 1 rem;
  color: white;
}
.GamedetailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.GamedetailsHeaderRight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.Gamedetails {
  margin-bottom: 30px;
}

.contactus {
  color: white;
  line-height: 1.5;
  align-items: flex-start;
}

.contactus i {
  display: inline;
  color: #ffffff;
}

.contactitem {
  font-size: 1rem;
  color: white;
}

.subcontactitem {
  font-size: 0.9rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  display: block;
}

.subcontactitem,
.subcontactitem:hover,
.subcontactitem:visited {
  color: white;
}
.btngrp1{
  display: none;
}

.ButtonStyle{
  display:flex;
  align-items:center;
  flex-direction: row;
}

.ImgStyle{
  /* width: 30%; */
  height: 30vh;
  width: 300px;
  object-fit: cover;
  padding: 10px;
}
.TitleStyle{
  font-family: 'NeueKabel';
  font-size: 2rem;
  font-weight: bold;
  color: white;
}

@media only screen and (max-width: 896px) {
  .GamefestNewCard {
    display: flex;
    flex-direction: column;
  }
  .GamedetailsHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
  }

  .description{
    padding: 10px;
  }
  .ImgStyle{
    padding: 10px;
    width: 65%;
  }
  .btngrp1{
    display: block;
    margin-top: -5%;
    font-size: 0.7rem;
    padding: 0.7rem;
  }
  .btngrp{
    display: none;
  }
  .buttons{
    align-items: left;
  }
  .ButtonStyle{
    background-image: linear-gradient(
      145deg,
      rgb(255 255 255 / 20%),
      rgb(255 255 255 / 1%)
    );
  }
  .TitleStyle{
    font-size: 1.6rem;
    align-items: flex-start;
    text-transform: uppercase;
    /* padding-left: 10px; */
  }
  .Gamedetails{
    align-items: flex-start;
    width:100%;
    padding-left: 10px;
  }
  .Gamedetails{
    margin-bottom: 0px;
  }
  .buttons{
    /* margin-top: -5%; */
    align-items: flex-start;
    margin-left: -18%;
  }
  /* .contactus{
    align-items: flex-start;
  } */
}
