.main {
  width: 300px;
  padding: 25px;
  border-radius: 1px;
}
.imgdiv {
  display: flex;
  justify-content: space-between;
}

.img1 {
  width: 120px;
  height: 100px;
  border: 3px solid white;
}

.img2 {
  width: 80px;
  height: 55px;
  border: 3px solid white;
}

.name {
  font-family: "Neuekabel";
  font-weight: bold;
  font-size: 1.5rem;
  color: #D4DBE2;
}

.title {
  color: #D4DBE2;
  font-family: "Neuekabel";
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 4px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.info {
  font-family: "Neuekabel";
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  color: #D4DBE2;
}

.button_ {
  color: #D4DBE2;
  border-image: conic-gradient(
      transparent,
      transparent,
      transparent,
      transparent,
      white,
      white,
      white,
      transparent,
      transparent,
      transparent,
      transparent,
      white,
      white,
      white,
      transparent
    )
    1;
  cursor: pointer;
  outline: none;
  height: 40px;
  width: 130px;
  background-color: #45537c;
  font-family: "Neuekabel";
  font-size: 1.1rem;
  font-weight: 600;
}

.button_:hover {
  transform: translateY(-2px);
  background-color: white;
  color: black;
  font-size: 1.1rem;
  font-weight: 200;
  border-image: conic-gradient(
      white,
      white,
      white,
      white,
      transparent,
      transparent,
      transparent,
      white,
      white,
      white,
      white,
      transparent,
      transparent,
      transparent,
      white
    )
    1;
  transition: 0.2s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .main {
    padding: 20px;
    width: 225px;
  }
  .img1 {
    width: 90px;
    height: 75px;
  }
  
  .img2 {
    width: 70px;
    height: 45px;
  }
  
  .name {
    font-size: 1.4rem;
  }
  
  .title {
    font-size: 1.1rem;
  }
  
  .info {
    font-size: .9rem;
  }
}
