.navouter {
  display: flex;
  justify-content: space-evenly;
  max-width: 50%;
  margin-inline: auto;
}
.onenav {
  color: #D4DBE2;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding: 1.5rem 1rem;
  line-height: 1;
  background: linear-gradient(180deg, #1f2a5c, #58638b);
  font-family: 'Azonix';
  /* background-color: #D4DBE2; */
  /* opacity: 0.2; */
}
.onenav:hover {
  cursor: pointer;
}
.active {
  border-bottom: 8px solid #D4DBE2;
}
.centerline {
  width: 0px;
  height: 100%;
  border-left: 7px dotted #D4DBE2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.card_left, .card_right {
  display: flex;
  justify-content: flex-start;
  padding-left: 120px;
  padding-right: 120px;
  position: relative;
}
.card_right {
  flex-flow: row-reverse;
}

.bike {
  position: -webkit-sticky;
  position: sticky;
  /* background-color: yellow; */
  font-size: 20px;
  top: 100px;
  /* position: fixed; */
  left: 50%;
  z-index: 2;
  /* top: 20px; */
  transform: translateX(-50%);
}
.middleCircleForEvent {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #D4DBE2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.outerCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #D4DBE2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.connectingline_left {
  height: 0px;
  width: 40%;
  border-top: 1px solid #D4DBE2;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%);
}
.width50 {
  /* to be removed in responsivenss */
  width: 50%;
  display: flex;
  align-items: center;
}
.width50_right {
  /* to be removed in responsivenss */
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.connectingline {
  flex-grow: 1;
  height: 0px;
  border: 1px solid #D4DBE2;
}

.time {
  color: #D4DBE2;
  font-size: 2rem;
  margin: 0 3rem;
  align-self: center;
  font-family: 'Azonix';
}
.timeinnerdiv {
  display: inline-block;
}
@media only screen and (max-width: 750px) {
  /* .centerline {
    display: none;
  }
  .bike {
    display: none;
  }
  .outerCircle {
    display: none;
  }
  .connectingline {
    display: none;
  } */
  .bike {
    width: 40px;
  }
  .middleCircleForEvent {
    left: 50%;
  }
  .card_left, .card_right {
    margin: 30px 0px;
    padding: 0;
  }
  .card_right {
    flex-flow: row;
  }
  .width50, .width50_right {
    margin-left: 25px;
  }
  .width50_right {
    flex-direction: row;
  }
  .centerline,
  .bike,
  .outerCircle {
    margin-left: 75%;
    left: 0;
  }
  .width50,
  .width50_right {
    width: 75%;
  }
  .time {
    width: 25%;
    margin: 0;
    font-size: 1.2rem;
    text-align: center;
  }
  .timeinnerdiv {
    display: block;
  }
  .onenav{
    font-size: 2rem;
  }
}