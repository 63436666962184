.container {
  font-family: 'NeueKabel';
  margin-top: 8px;
  margin-left: 10px;
  padding: 10px;
  font-family: 'NeueKabel';
}

.form {
  /* position: absolute; */
  z-index: 10;
}

select {
  background-color: transparent;
  border: none;
}

option {
  background-color: #223175;
  border: none;
  outline: none;
}
select:hover {
  cursor: pointer;
}
option:hover {
  cursor: pointer;
}

@media (min-width: 997px) {
  .form {
    left: 90%;
  }
  .presignupform {
    position: absolute;
    right: 10%;
    top: 42%;
    width: 430px;
    background-image: linear-gradient(
      145deg,
      rgb(255 255 255 / 35%),
      rgb(255 255 255 / 10%)
    );
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .signupform {
    right: 10%;
    top: 12%;
    width: 430px;
  }
}

.signupform {
  position: absolute;
  top: 20%;
  background-image: linear-gradient(
    145deg,
    rgb(255 255 255 / 35%),
    rgb(255 255 255 / 10%)
  );
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.icons {
  font-size: 30px;
  color: #fff;
  margin-top: 10px;
}

.inputRow {
  display: flex;
}

.input_icons i {
  position: absolute;
}

.input_icons {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.input_icons i {
  color: white;
}

.signupPage_icons {
  width: 17px;
  position: absolute;
  margin: 12px 0px 0 8px;
  color: white;
}

svg[data-icon='phone-volume'] {
  transform: rotate(-45deg);
}

input:focus {
  outline: none;
}

input[type='text'] {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #42649e;
}

.icon {
  padding: 10px;
  min-width: 40px;
}

.input_field {
  width: 100%;
  padding: 10px;
  /* text-align: center; */
  outline: none;
  color: white;
}

.password_field {
  -webkit-text-security: disc;
  padding-right: 35px;
}

.with_icon {
  padding-left: 35px;
}

.input_field::placeholder {
  color: white;
  opacity: 1;
}

@media (max-width: 996px) {
  .form {
    position: absolute;
    z-index: 10;
    top: 7%;
    /* left: 90%; */
  }
  .presignupform {
    position: relative;
    /* right: 10%; */
    top: 42%;
    left: 6%;
    /* right: 8%; */
    width: 90vw;
    background-image: linear-gradient(
      145deg,
      rgb(255 255 255 / 35%),
      rgb(255 255 255 / 10%)
    );
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .signupform {
    position: absolute;
    top: 30%;
    left: 2%;
    width: 90vw;
    transform: translateX(5.5%);
    background-image: linear-gradient(
      145deg,
      rgb(255 255 255 / 35%),
      rgb(255 255 255 / 10%)
    );
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  }
  .inputCol {
    flex: 2;
  }
  @media (max-width: 396px) {
    input {
      font-size: 11px;
    }
    button {
      font-size: 11px;
    }
  }
  @media (max-width: 345px) {
    input {
      font-size: 9px;
    }
    button {
      font-size: 9px;
    }
  }
  @media (min-device-width: 767px) and (max-device-width: 996px) {
    input {
      font-size: 19px;
    }
    button {
      font-size: 19px;
    }
  }
}

.error_message {
  color: red;
}
